console.info('NODE_ENV', process?.env?.NODE_ENV);
export const __DEV__ = process?.env?.NODE_ENV === 'development';
// @ts-ignore
console.info('ENV_VARS', process?.env);
if (!__DEV__) {
  console.debug = () => {};
  console.log = () => {};
}

export const localConfig = {
  // PRODUCTION
  REACT_APP_PROFILE_FLAG: '1',
  REACT_APP_ONBOARDING_FLAG: '0',
  SITE_KEY: '6Lcf7MgaAAAAANwsV0EEfjIjOQEKaHSNvfiwXJn3', // Captcha - FIXME env
  STRIPE_KEY:
    'pk_live_51IQYoHIcbdUWJa9isBXQLHcEYgQuwLRafctUCZqUMDyYFnE5aiQOgjYTf6k3nDfSLQEsEo5lL7n2k32EAVtvaHR000FUBNz2dM', // FIXME env
  TENANT: '' as string | undefined,
  GCP_API_KEY: process.env.VITE_API_GCP_API_KEY,
  GCP_AUTH_DOMAIN: process.env.VITE_API_GCP_AUTH_DOMAIN,
};

export const apiUrl = (() => {
  switch (window.location.origin) {
    case 'https://my.ionnyk.com':
      localConfig.STRIPE_KEY =
        'pk_live_51IQYoHIcbdUWJa9isBXQLHcEYgQuwLRafctUCZqUMDyYFnE5aiQOgjYTf6k3nDfSLQEsEo5lL7n2k32EAVtvaHR000FUBNz2dM'; // FIXME env
      localConfig.TENANT = process.env.REACT_APP_TENANT_PROD;
      return process?.env?.REACT_APP_API_BASE_URL_PROD;
    case 'http://localhost:3000':
    case 'http://127.0.0.1:3000':
    case 'https://portal.ionnyk-dev.inkcoming.eu':
      // DEV
      // localConfig.REACT_APP_PROFILE_FLAG = '1';
      // localConfig.REACT_APP_ONBOARDING_FLAG = '0';
      // localConfig.SITE_KEY = '6Ldthb0aAAAAAPlJo0T8tVUc0wzNwonBTWNyu7Um'; // FIXME env
      // localConfig.STRIPE_KEY =
      //   'pk_test_51KlskdDOrVY2QvfKLsEFTQhmbMulQQlQRRfLEeouQu64R9nMrH4kCXlC1Hms3oPtMomI1nbTRHLp5WoUEFKQYxP800AHbsot48'; // FIXME env
      // return 'http://localhost:8080';
      // return 'https://a690-109-135-26-232.eu.ngrok.io';
      // DEV :
      localConfig.REACT_APP_PROFILE_FLAG = '1';
      localConfig.REACT_APP_ONBOARDING_FLAG = '0'; // FIXME to get back
      localConfig.SITE_KEY = '6Ldthb0aAAAAAPlJo0T8tVUc0wzNwonBTWNyu7Um'; // FIXME env DEV
      localConfig.STRIPE_KEY =
        'pk_test_51IQYoHIcbdUWJa9iYwPRM9VLM3OH4eSQFYy1p3BAnLRwVGRQxubcKQkiVsWUihHs6uanJDvhY2PbykhPkiy9eCoI00VUpN0nOo'; // FIXME env DEV
      localConfig.TENANT = process.env.REACT_APP_TENANT_DEV;
      return 'https://api-dev.inkcoming.eu';
    case 'https://admin.akom-dev-1.inkcoming.eu':
    case 'https://ionnyk-portal-staging.inkcoming.eu': // @deprecated
    case 'https://portal.ionnyk-staging.inkcoming.eu':
    default:
      // STAGING
      localConfig.REACT_APP_PROFILE_FLAG = '1';
      localConfig.REACT_APP_ONBOARDING_FLAG = '0'; // like prod
      localConfig.SITE_KEY = '6Ldthb0aAAAAAPlJo0T8tVUc0wzNwonBTWNyu7Um'; // FIXME env
      localConfig.STRIPE_KEY =
        'pk_test_51IQYoHIcbdUWJa9iYwPRM9VLM3OH4eSQFYy1p3BAnLRwVGRQxubcKQkiVsWUihHs6uanJDvhY2PbykhPkiy9eCoI00VUpN0nOo'; // FIXME env
      localConfig.TENANT = process.env.REACT_APP_TENANT_STAGING;
      return 'https://api-staging.inkcoming.eu';
  }
})();
export const BASE_URL = apiUrl + '/client/v1/';
export const BASE_URL_ANON = apiUrl + '/anon/v1/';
export const ROBOT_URL_ANON = apiUrl + '/robot/v1/';
console.info('apiUrl', apiUrl, localConfig);
