import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Pages from './pages';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation'; //

/* The following line can be included in your src/index.js or App.js file*/
// import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.scss';
// Put any other imports below so that CSS from your
// components takes precedence over default styles.
import './index.css';

import { Provider } from 'react-redux';
import { store } from './stores/store';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);

// Change the target container for Portal-related elements so that they are injected into the root element.
const theme = createTheme({
  components: {
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiModal: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
  palette: {
    primary: {
      main: '#222222',
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#666666',
      // dark: will be calculated from palette.secondary.main,
    },
    success: { main: '#333333' },
    error: { main: '#434343' }, // 'no danger'
    warning: { main: '#5f5f5f' },
    info: { main: '#515151' },
  },
});

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      {/* <React.StrictMode> */}
      <Provider store={store}>
        <ProtectedRoute component={Pages} />
      </Provider>
      {/* </React.StrictMode> */}
    </ThemeProvider>
  </StyledEngineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
