import Masonry from '@mui/lab/Masonry';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const isMobile = () => {
  return window.innerWidth <= 768; // Adjust breakpoint as needed
};

export type ImageItem = {
  uuid: string;
  title: string;
  source: string;
  preview_url: string;
};

type Props = {
  images: ImageItem[];
  onSelectItem?: (uuid: string) => Promise<void>;
  loading: boolean;
  style?: any;
  loadingUuid?: string;
};

const Label = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: 'center',
  fontSize: '0.7rem',
  color: theme.palette.text.secondary,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
}));

export const ImageGrid = (p: Props) => {
  const { images = [], onSelectItem, loading = false, style = {}, loadingUuid = '' } = p;
  console.log('ImageGrid RENDER', { style });
  return (
    <div
      style={{
        flex: 1,
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '100%',
        padding: '1rem',
        overflowY: 'auto',
      }}
    >
      {!!images.length && (
        <Masonry
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          // IF less than 2 or 5, the items will not be horizontally centered
          columns={isMobile() ? 2 : 5}
          spacing={4}
        >
          {images.map((item, index) => (
            <div key={index}>
              <div
                style={{
                  position: 'relative',
                }}
              >
                <img
                  onClick={() => !loading && onSelectItem?.(item.uuid)}
                  loading="eager"
                  src={`${item.preview_url}`}
                  alt={item.title}
                  style={{
                    //   opacity: loading ? 0.5 : 1,
                    cursor: loading ? 'progress' : 'pointer',
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    display: 'block',
                    width: '100%',
                  }}
                />
                {/* Overlay when loading */}
                {loadingUuid === item.uuid && (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(255, 255, 255, 0.5)', // White with 50% opacity
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                      fontSize: '1.2rem',
                      color: 'black', // Ensure text is visible
                      borderTopLeftRadius: 4,
                      borderTopRightRadius: 4,
                    }}
                  >
                    Loading...
                  </div>
                )}
              </div>
              <Label>
                <>
                  <span
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {loading ? 'Loading...' : item.title}
                  </span>
                  <br />
                  <span
                    style={{
                      fontSize: '0.6rem',
                    }}
                  >
                    {item.source}
                  </span>
                </>
              </Label>
            </div>
          ))}
        </Masonry>
      )}
    </div>
  );
};
