import React from 'react';
import { switchLang } from '../../redux/appSlice';
import { AppLangs, useTranslation } from '../../hooks/useTranslation';
import { useAppDispatch } from '../../hooks/reduxTKHooks';

export const Footer = () => {
  const dispatch = useAppDispatch();
  const t = useTranslation();
  return (
    <>
      <small className="text-center" style={{ marginTop: '25px', width: '100%' }}>
        {/* FIXME not set */}
        {(process?.env?.REACT_APP_PORTAL_VERSION || 'local') + ' - '}
        <a href="#" onClick={() => dispatch(switchLang(AppLangs.en))}>
          EN
        </a>
        <a href="#" onClick={() => dispatch(switchLang(AppLangs.fr))}>
          |FR
        </a>
      </small>
      <small className="text-center" style={{ marginBottom: 'auto', width: '100%' }}>
        {'© Ionnyk, 2025. All Rights Reserved. '}
      </small>
    </>
  );
};
