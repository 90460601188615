import { useCallback, useEffect, useState } from 'react';

export const rewriteLocation = (newSubPath: string) => {
  const { location, history } = window;
  history.replaceState({}, '', location.origin + newSubPath);
};

export function useURLParams<T extends Readonly<string>>(...paramsNames: T[]) {
  const { location } = window;
  const [result, setResult] = useState<Record<T, string>>({} as Record<T, string>);
  const [loading, setLoading] = useState(true);
  console.debug('useURLParams', { location, paramsNames });
  const detectUrlParams = useCallback(() => {
    if (paramsNames) {
      const query = new URLSearchParams(location.search);
      const newResult: Record<string, string> = {};
      paramsNames.forEach((param) => {
        const value = query.get(param);
        console.log(location.search, param, { value });
        if (param?.length && value?.length) {
          newResult[param] = value;
          console.info('detectUrlParams', param, value);
        }
      });
      if (Object.keys(newResult).length) {
        setResult((oldResult) => ({
          ...oldResult,
          ...newResult,
        }));
        rewriteLocation(location.pathname);
      }
    }
    setLoading(false);
  }, [location.pathname, location.search, paramsNames]);

  useEffect(() => detectUrlParams(), [detectUrlParams]);
  return [result, loading] as [Record<T, string>, boolean];
}
