import { ImageItem } from './ImageGrid';

export const gallery: ImageItem[] = [
  {
    uuid: 'fcbacb9c-ffdd-4680-9faa-7678167cf43f',
    title: '«Luxury hotels»',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/fcbacb9c-ffdd-4680-9faa-7678167cf43f/master/LG',
  },
  {
    uuid: 'f6ba58d0-aafe-441b-a9e4-8822b676e3a3',
    title: '« Niki De Saint Phalle » ',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/f6ba58d0-aafe-441b-a9e4-8822b676e3a3/master/LG',
  },
  {
    uuid: '7cebd67c-57d8-4185-bec8-cb2715e75abc',
    title: '« Simone Veil »',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/7cebd67c-57d8-4185-bec8-cb2715e75abc/master/LG',
  },
  // {
  //   uuid: 'c9b05f1b-547e-49f7-b3a1-5b1319a9be1e',
  //   title: 'Le japonisme',
  //   source: 'Paris Match / Scoop',
  //   preview_url:
  //     'https://storage.googleapis.com/akom-previews-prod/c9b05f1b-547e-49f7-b3a1-5b1319a9be1e/master/LG',
  // },
  {
    uuid: '003232bf-2fca-43ff-9875-4ebd37e471b6',
    title: '«Fly like a butterfly and sting like a bee»',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/003232bf-2fca-43ff-9875-4ebd37e471b6/master/LG',
  },
  {
    uuid: 'c0cf5a2f-6ece-4547-881f-1409e83b172b',
    title: 'Salvador Dali - Lithographic stone',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/c0cf5a2f-6ece-4547-881f-1409e83b172b/master/LG',
  },
  {
    uuid: 'e6e49465-3ff2-4cd5-b75f-29d665682316',
    title: 'Mercedes 300sl',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/e6e49465-3ff2-4cd5-b75f-29d665682316/master/LG',
  },
  {
    uuid: '30c1abf7-2ac5-4529-8774-7d4bd9cbe7bf',
    title: 'Jane Birkin',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/30c1abf7-2ac5-4529-8774-7d4bd9cbe7bf/master/LG',
  },
  {
    uuid: '91b704f8-55b3-4dba-8193-05cdc0177a45',
    title: '«Sophia Loren eating pasta»',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/91b704f8-55b3-4dba-8193-05cdc0177a45/master/LG',
  },
  {
    uuid: '83462f38-6e84-48e6-ab27-7f4e2151ffa6',
    title: '«Sophia Loren wearing a bibi»',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/83462f38-6e84-48e6-ab27-7f4e2151ffa6/master/LG',
  },
  {
    uuid: '77b86f55-1fbc-45f9-acd4-c10a5f636262',
    title: '«Sophia Loren takes off for Saint-Tropez»',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/77b86f55-1fbc-45f9-acd4-c10a5f636262/master/LG',
  },
  {
    uuid: 'aac28259-819c-48e4-80d9-f4dff1f0d0fa',
    title: '«Sophia Loren looking at exotic birds»',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/aac28259-819c-48e4-80d9-f4dff1f0d0fa/master/LG',
  },
  {
    uuid: '99b88615-3e70-4efd-9f03-a5f0e1916542',
    title: '«Sophia Loren with a flowery hat»',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/99b88615-3e70-4efd-9f03-a5f0e1916542/master/LG',
  },
  {
    uuid: '02823214-2800-4de3-ab93-641c68bfe2a7',
    title: '«Sophia Loren by her pool»',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/02823214-2800-4de3-ab93-641c68bfe2a7/master/LG',
  },
  {
    uuid: 'a148c397-4903-4576-8fcf-5bd2f084c000',
    title: '«Sophia Loren to meet Queen Elisabeth II»',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/a148c397-4903-4576-8fcf-5bd2f084c000/master/LG',
  },
  {
    uuid: '2ab1b531-f1a0-4bb2-9a62-3a9d7f2accf8',
    title: '1972 Citroen DS21',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/2ab1b531-f1a0-4bb2-9a62-3a9d7f2accf8/master/LG',
  },
  {
    uuid: 'de775123-ec7e-4538-a2e7-7a6167e84ac9',
    title: 'Waiting For You',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/de775123-ec7e-4538-a2e7-7a6167e84ac9/master/LG',
  },
  {
    uuid: '5f50715b-39cf-4278-99f7-85bffa141ad5',
    title: 'Race car',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/5f50715b-39cf-4278-99f7-85bffa141ad5/master/LG',
  },
  {
    uuid: '2d10bc58-a53e-449d-8cff-61f7f6fe802d',
    title: 'Mercedes 300sl Gullwing ',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/2d10bc58-a53e-449d-8cff-61f7f6fe802d/master/LG',
  },
  {
    uuid: 'd31ddac1-0f71-4020-a5b0-4623986bc4d0',
    title: 'Lamborghini Countach',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/d31ddac1-0f71-4020-a5b0-4623986bc4d0/master/LG',
  },
  // {
  //   uuid: 'e82dc4b4-c952-48c7-bc6f-86ddfd983213',
  //   title: 'Samourai look',
  //   source: 'Paris Match / Scoop',
  //   preview_url:
  //     'https://storage.googleapis.com/akom-previews-prod/e82dc4b4-c952-48c7-bc6f-86ddfd983213/master/LG',
  // },
  {
    uuid: 'eb08b64a-65fb-4f24-b444-ffe6025ce5c8',
    title: 'Porsche',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/eb08b64a-65fb-4f24-b444-ffe6025ce5c8/master/LG',
  },
  // {
  //   uuid: '8845ae36-bd8c-4d63-a585-e52e54280624',
  //   title: 'The weight of fashion',
  //   source: 'Paris Match / Scoop',
  //   preview_url:
  //     'https://storage.googleapis.com/akom-previews-prod/8845ae36-bd8c-4d63-a585-e52e54280624/master/LG',
  // },
  {
    uuid: 'dbce9a43-0e08-4c2d-baef-f8471d8eed72',
    title: '«Jacques Brel prepares for his Olympia, 1961»',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/dbce9a43-0e08-4c2d-baef-f8471d8eed72/master/LG',
  },
  {
    uuid: 'da7857fe-f4b7-426f-94dd-8282e80dde9b',
    title: '«An unfinished journey»',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/da7857fe-f4b7-426f-94dd-8282e80dde9b/master/LG',
  },
  {
    uuid: '56f84c72-434f-4ca6-be7b-e93030c0c9b7',
    title: '«Mythical songs»',
    source: 'Paris Match / Scoop',
    preview_url:
      'https://storage.googleapis.com/akom-previews-prod/56f84c72-434f-4ca6-be7b-e93030c0c9b7/master/LG',
  },
  // {
  //   uuid: '6e88b0e5-ce56-40ad-a7cf-e56df6589adf',
  //   title: 'Geishas',
  //   source: 'Paris Match / Scoop',
  //   preview_url:
  //     'https://storage.googleapis.com/akom-previews-prod/6e88b0e5-ce56-40ad-a7cf-e56df6589adf/master/LG',
  // },
  // {
  //   uuid: 'bb60a0e0-861a-4642-9345-73ae2234dfaf',
  //   title: 'Crossing The Line',
  //   source: 'Paris Match / Scoop',
  //   preview_url:
  //     'https://storage.googleapis.com/akom-previews-prod/bb60a0e0-861a-4642-9345-73ae2234dfaf/master/LG',
  // },
  // {
  //   uuid: '232af200-caee-4c17-84ba-bb6154c47b8b',
  //   title: 'Traditional values',
  //   source: 'Paris Match / Scoop',
  //   preview_url:
  //     'https://storage.googleapis.com/akom-previews-prod/232af200-caee-4c17-84ba-bb6154c47b8b/master/LG',
  // },
  // {
  //   uuid: 'b0aca0b8-a80c-4e5f-bc49-f4b3766f2443',
  //   title: 'Fine Yellow Lace',
  //   source: 'Paris Match / Scoop',
  //   preview_url:
  //     'https://storage.googleapis.com/akom-previews-prod/b0aca0b8-a80c-4e5f-bc49-f4b3766f2443/master/LG',
  // },
];
