import axios, { AxiosInstance } from 'axios';
import { UNAUTHORIZED_STATUS } from './helper';
import { store } from '../stores/store';
import { logoutAsync } from '../redux/signinSlice';
import { BASE_URL, BASE_URL_ANON, ROBOT_URL_ANON } from './baseURLs';
const DEFAULT_API_REQUEST_TIMEOUT = 5000;

const axiosBaseConfig = {
  timeout: DEFAULT_API_REQUEST_TIMEOUT,
  headers: {
    'ngrok-skip-browser-warning': true,
    Accept: 'application/json',
  },
};

export const anonApi: AxiosInstance = axios.create({
  ...axiosBaseConfig,
  baseURL: BASE_URL_ANON,
});

export const robotApi: AxiosInstance = axios.create({
  ...axiosBaseConfig,
  baseURL: ROBOT_URL_ANON,
});

export const authApi: AxiosInstance = axios.create({
  ...axiosBaseConfig,
  baseURL: BASE_URL,
});

// AUTH - FIXME TO COMMON
let interceptorID: number | null = null;
export function installBasicAuthHeader(token: string) {
  if (interceptorID != null) {
    authApi.interceptors.request.eject(interceptorID);
  }
  interceptorID = authApi.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Basic ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

// FIXME update commont-ts
const interceptorsMap: Record<string, number | null> = {};
export function installBearerAuthHeader(
  axiosInstance: AxiosInstance,
  apiName: string,
  token: string
) {
  axiosInstance.interceptors.request.clear();
  interceptorsMap[apiName] = axiosInstance.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => Promise.reject(error)
  );
  axiosInstance.interceptors.response.clear();
  axiosInstance.interceptors.response.use(
    (response) => {
      if (
        response.status === UNAUTHORIZED_STATUS &&
        window.confirm('Session expired. Please login again')
      ) {
        console.warn('Session expired. Please login again');
        store.dispatch(logoutAsync());
      }
      return response;
    },
    (error) => Promise.reject(error)
  );
}

export const onBearerTokenRefresh = (bearerToken: string) => {
  installBearerAuthHeader(authApi, 'authApi', bearerToken);
};
