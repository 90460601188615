import React from 'react';
import { TKeys } from '../i18n/en';
import { PublicPayGate } from './PublicPayGate';
import { PublicLoading } from './PublicLoading';
import { PublicPaySuccess } from './PublicPaySuccess';
import { PublicPayCancel } from './PublicPayCancel';
import { PublicForgotPsw } from './PublicForgotPsw';
import { PublicResetPsw } from './PublicResetPsw';
import { Subs } from './Subs/Subs';
import { localConfig } from '../api/baseURLs';
import { Home } from './Home/Home';
// import {Voucher} from './Voucher/Voucher';
import { Profile } from './Profile/Profile';
import { PublicWall } from './PublicWall';

export interface RouteIF {
  Component: any;
  label: string;
  path: string;
  order: number;
  key: string;
  public?: boolean;
}

// all app routes
// label = tsl key
export const routes: Record<string, RouteIF> = {
  home: {
    Component: Home,
    label: 'Home' as TKeys,
    path: '/',
    order: 1,
    key: 'home',
  },
  profile: {
    Component: Profile,
    label: 'Profile' as TKeys,
    path: '/profile',
    order: 2,
    key: 'profile',
  },
  subs: {
    Component: Subs,
    label: 'Subs' as TKeys,
    path: '/subscriptions',
    order: 3,
    key: 'subs',
  },
  // FIXME restore
  // voucher: {
  //   Component: Voucher,
  //   label: 'Voucher' as TKeys,
  //   path: '/voucher',
  //   order: 4,
  //   key: 'voucher',
  // },
  checkout: {
    Component: PublicPayGate,
    label: 'Checkout',
    path: '/checkout',
    order: 1,
    public: true,
    key: 'checkout',
  },
  pay_success: {
    Component: PublicPaySuccess,
    label: 'PublicPaySuccess',
    path: '/pay_success',
    order: 1,
    public: true,
    key: 'pay_success',
  },
  pay_cancel: {
    Component: PublicPayCancel,
    label: 'PublicPayCancel',
    path: '/pay_cancel',
    order: 1,
    public: true,
    key: 'pay_cancel',
  },
  loading: {
    Component: PublicLoading,
    label: 'Loading',
    path: '/loading',
    order: 1,
    public: true,
    key: 'loading',
  },
  forgotPsw: {
    Component: PublicForgotPsw,
    label: 'Forgot password',
    path: '/forgot-password',
    order: 1,
    public: true,
    key: 'forgot-password',
  },
  resetPsw: {
    Component: PublicResetPsw,
    label: 'Reset password',
    path: '/reset-password',
    order: 1,
    public: true,
    key: 'reset-password',
  },
  publicWall: {
    Component: PublicWall,
    label: 'Public wall',
    path: '/public-wall',
    order: 1,
    public: true,
    key: 'public-wall',
  },
};

// FIXME remove feature flags and localConfig
console.info('env', localConfig);
if (localConfig.REACT_APP_PROFILE_FLAG !== '1') {
  // @ts-ignore
  delete routes.profile;
}
if (localConfig.REACT_APP_ONBOARDING_FLAG !== '1') {
  // @ts-ignore
  delete routes.home;
  routes.profile.path = '/';
}

// @ts-ignore
export const sortedPrivateRoutes: RouteIF[] = Object.keys(routes)
  // @ts-ignore
  .filter((k: any) => !routes[k].public)
  // @ts-ignore
  .map((k: string) => ({ ...routes[k], key: k }))
  // @ts-ignore
  .sort((a, b) => a.order > b.order);
//export const sortedPrivateRoutes: RouteIF[] = sortRoutes(routes, true);
// // @ts-ignore
export const sortedPublicRoutes: RouteIF[] = Object.keys(routes)
  // @ts-ignore
  .filter((k: any) => routes[k].public)
  // @ts-ignore
  .map((k: string) => ({ ...routes[k], key: k }))
  // @ts-ignore
  .sort((a, b) => a.order > b.order);
