import { useEffect, useState } from 'react';
import { ImageGrid, ImageItem } from './ImageGrid';
import { useURLParams } from './useURLParams';
import { sleep } from '../../utils';
import { gallery } from './constants';
import { robotApi } from '../../api/api';

export const PublicWall = () => {
  const [params, paramsLoading] = useURLParams('token', 'group_uuid', 'frame_uuid', 'type');
  const [loading, setLoading] = useState(false);
  const [loadingUuid, setLoadingUuid] = useState('');
  const [images, setImages] = useState<ImageItem[]>(gallery);

  const hasMissingToken = !params?.token && !paramsLoading;
  const globalLoading = loading || paramsLoading;

  const onSelectItem = async (uuid: string) => {
    // if (!window.confirm(`Do you want to select image with uuid: ${uuid}`)) {
    //   return;
    // }
    setLoading(true);
    setLoadingUuid(uuid);
    try {
      console.debug('Selecting image', {
        params,
        uuid,
      });
      await robotApi.post(
        `group/${params.group_uuid}/schedule`,
        {
          frameUuid: params.frame_uuid,
          type: params.type,
          artworkUuid: uuid,
        },
        {
          headers: {
            'x-api-key': `${params.token}`,
          },
        }
      );
      alert('The image will soon be displayed on the frame.');
    } catch (e: any) {
      alert('Error selecting image: ' + e?.message);
    }
    setLoading(false);
    setLoadingUuid('');
  };

  useEffect(() => {
    if (params.token && params.group_uuid && params.type && params.frame_uuid) {
      console.info('Found params', params);
      setLoading(true);
      sleep(500)
        .then(() => {
          setImages(gallery);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setImages([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.frame_uuid, params.group_uuid, params.token, params.type]);

  const title = hasMissingToken ? 'Missing token' : globalLoading ? 'Loading ...' : `IONNYK`;
  // TODO remove
  // const subtitle = `Debug: group=${params.group_uuid}  frame=${params.frame_uuid} type=${params.type}`;
  const subtitle = 'Choose an artwork ...';

  return (
    <div
      style={{
        padding: 20,
        color: 'white',
        backgroundColor: 'black',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
      }}
    >
      <h1
        style={{
          position: 'static',
          top: 0,
          width: '100%',
          textAlign: 'center',
          marginBottom: 15,
        }}
      >
        {title}
      </h1>
      <h5
        style={{
          position: 'static',
          top: 0,
          width: '100%',
        }}
      >
        {subtitle}
      </h5>

      <ImageGrid
        loadingUuid={loadingUuid}
        onSelectItem={onSelectItem}
        images={images}
        loading={globalLoading}
      />

      <span style={{ color: 'white', fontSize: 14, marginTop: 15, textAlign: 'center' }}>
        For more information{' '}
        <a
          style={{ color: 'white', textDecoration: 'underline' }}
          href="https://www.ionnyk.com/horus-gallery"
          target="_blank"
          rel="noreferrer"
        >
          click here.
        </a>
        <br />
        IONNYK 2025
      </span>
    </div>
  );
};
